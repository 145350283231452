export const traslations = {
    en: {
        "generic-continue": `CONTINUE`,
        "generic-skip": `SKIP`,
        "generic-yes": `YES`,
        "step0-warn1": `This is NOT a serious dating site!`,
        "step0-warn2": `<b>WARNING!</b> The site may contain photos of girls you may know!`,
        "step0-ok": `ALRIGHT`,
        "step7-thanks": `THANKS!`,
        "step7-message": `You can now see the list of women in your area and view their naughty photos. Click the "Continue" button and proceed to registration.`,
        "step7-warn": `Please, keep confidentiality of our members.`,
        "step7-continue": `CONTIUNE`
    },
    es: {
        "generic-continue": `CONTINUAR`,
        "generic-skip": `OMITIR`,
        "generic-yes": `SÍ`,
        "step0-warn1": `¡Este no es un sitio de citas serio!`,
        "step0-warn2": `<b>¡ADVERTENCIA!</b> ¡El sitio puede contener fotos de chicas que conoces!`,
        "step0-ok": `DE ACUERDO`,
        "step7-thanks": `¡GRACIAS!`,
        "step7-message": `Ahora puedes ver la lista de mujeres en tu área y ver sus fotos traviesas. Haz clic en el botón "Continuar" y procede a la inscripción.`,
        "step7-warn": `Por favor, mantén la confidencialidad de nuestros miembros.`,
        "step7-continue": `CONTINUAR`
    },
    fr: {
        "generic-continue": `CONTINUER`,
        "generic-skip": `PASSER`,
        "generic-yes": `OUI`,
        "step0-warn1": `Ce n'est PAS un site de rencontre sérieux!`,
        "step0-warn2": `<b>ATTENTION!</b> Le site peut contenir des photos de filles que vous connaissez!`,
        "step0-ok": `D'ACCORD`,
        "step7-thanks": `MERCI!`,
        "step7-message": `Vous pouvez maintenant voir la liste des femmes dans votre région et voir leurs photos coquines. Cliquez sur le bouton "Continuer" et procédez à l'inscription.`,
        "step7-warn": `Veuillez respecter la confidentialité de nos membres.`,
        "step7-continue": `CONTINUER`
    },
    fa: {
        "generic-continue": `ادامه`,
        "generic-skip": `رد کردن`,
        "generic-yes": `بله`,
        "step0-warn1": `این یک سایت جدی برای دوستیابی نیست!`,
        "step0-warn2": `<b>هشدار!</b> این سایت ممکن است شامل تصاویر دخترانی باشد که شما آنها را می‌شناسید!`,
        "step0-ok": `خوب`,
        "step7-thanks": `ممنون!`,
        "step7-message": `شما می‌توانید اکنون لیست زنان در منطقه خود را ببینید و عکس‌های شیطنت‌آمیز آنها را مشاهده کنید. روی دکمه "ادامه" کلیک کنید و به ثبت نام ادامه دهید.`,
        "step7-warn": `لطفاً حریم خصوصی اعضای ما را رعایت کنید.`,
        "step7-continue": `ادامه`
    },
    it: {
        "generic-continue": `CONTINUA`,
        "generic-skip": `SALTA`,
        "generic-yes": `SI`,
        "step0-warn1": `Questo NON è un sito di incontri serio!`,
        "step0-warn2": `<b>ATTENZIONE!</b> Il sito potrebbe contenere foto di ragazze che conosci!`,
        "step0-ok": `VA BENE`,
        "step7-thanks": `GRAZIE!`,
        "step7-message": `Ora puoi vedere la lista di donne nella tua zona e vedere le loro foto piccanti. Clicca sul pulsante "Continua" e procedi alla registrazione.`,
        "step7-warn": `Per favore, mantieni la confidenzialità dei nostri membri.`,
        "step7-continue": `CONTINUA`
    },
    de: {
        "generic-continue": `WEITER`,
        "generic-skip": `ÜBERSPRINGEN`,
        "generic-yes": `JA`,
        "step0-warn1": `Dies ist KEINE ernsthafte Dating-Site!`,
        "step0-warn2": `<b>WARNUNG!</b> Die Website kann Fotos von Mädchen enthalten, die Sie kennen!`,
        "step0-ok": `OKAY`,
        "step7-thanks": `DANKE!`,
        "step7-message": `Sie können jetzt die Liste der Frauen in Ihrer Region sehen und ihre frechen Fotos ansehen. Klicken Sie auf die Schaltfläche "Weiter" und fahren Sie mit der Registrierung fort.`,
        "step7-warn": `Bitte halten Sie die Vertraulichkeit unserer Mitglieder ein.`,
        "step7-continue": `WEITER`
    },
    ro: {
        "generic-continue": `CONTINUĂ`,
        "generic-skip": `SARI`,
        "generic-yes": `DA`,
        "step0-warn1": `Acesta NU este un site serios de întâlniri!`,
        "step0-warn2": `<b>ATENȚIE!</b> Site-ul poate conține fotografii cu fete pe care ar putea sa le cunoști!`,
        "step0-ok": `ÎN REGULĂ`,
        "step7-thanks": `MULȚUMESC!`,
        "step7-message": `Acum poți vedea lista de femei din zona ta și viziona fotografiile lor indecente. Apasă butonul "Continuă" și continuă înregistrarea.`,
        "step7-warn": `Vă rugăm să păstrați confidențialitatea membrilor noștri.`,
        "step7-continue": `CONTINUĂ`
    },
    ar: {
        "generic-continue": `استمر`,
        "generic-skip": `تخطى`,
        "generic-yes": `نعم`,
        "step0-warn1": `هذا ليس موقعًا جدي للتعارف!`,
        "step0-warn2": `<b>تحذير!</b> قد يحتوي الموقع على صور لفتيات تعرفهن!`,
        "step0-ok": `حسنًا`,
        "step7-thanks": `شكرًا!`,
        "step7-message": `يمكنك الآن رؤية قائمة النساء في منطقتك وعرض صورهن الجريئة. انقر فوق زر "استمر" وتابع التسجيل.`,
        "step7-warn": `يرجى الحفاظ على سرية أعضائنا.`,
        "step7-continue": `استمر`
    },
    id: {
        "generic-continue": `LANJUT`,
        "generic-skip": `LEWATI`,
        "generic-yes": `YA`,
        "step0-warn1": `Ini BUKAN situs kencan serius!`,
        "step0-warn2": `<b>PERINGATAN!</b> Situs ini dapat berisi foto-foto wanita yang Anda kenal!`,
        "step0-ok": `OK`,
        "step7-thanks": `TERIMA KASIH!`,
        "step7-message": `Anda sekarang dapat melihat daftar wanita di daerah Anda dan melihat foto nakal mereka. Klik tombol "Lanjut" dan lanjutkan ke registrasi.`,
        "step7-warn": `Mohon menjaga kerahasiaan anggota kami.`,
        "step7-continue": `LANJUTKAN`
    },
    pt: {
        "generic-continue": `CONTINUAR`,
        "generic-skip": `PULAR`,
        "generic-yes": `SIM`,
        "step0-warn1": `Este NÃO é um site de relacionamento sério!`,
        "step0-warn2": `<b>ATENÇÃO!</b> O site pode conter fotos de garotas que você conhece!`,
        "step0-ok": `OK`,
        "step7-thanks": `OBRIGADO(A)!`,
        "step7-message": `Agora você pode ver a lista de mulheres na sua área e ver as fotos atrevidas delas. Clique no botão "Continuar" e prossiga com o registro.`,
        "step7-warn": `Por favor, mantenha a confidencialidade dos nossos membros.`,
        "step7-continue": `CONTINUAR`
    },
    sv: {
        "generic-continue": `FORTSÄTT`,
        "generic-skip": `HOPPA ÖVER`,
        "generic-yes": `JA`,
        "step0-warn1": `Detta är INTE en seriös dejtingsajt!`,
        "step0-warn2": `<b>VARNING!</b> Sidan kan innehålla bilder på tjejer du känner!`,
        "step0-ok": `OK`,
        "step7-thanks": `TACK!`,
        "step7-message": `Nu kan du se listan över kvinnor i ditt område och titta på deras snuskiga bilder. Klicka på knappen "Fortsätt" och gå vidare till registreringen.`,
        "step7-warn": `Var god respektera våra medlemmars integritet.`,
        "step7-continue": `FORTSÄTT`
    },
    no: {
        "generic-continue": `FORTSETT`,
        "generic-skip": `HOPP OVER`,
        "generic-yes": `JA`,
        "step0-warn1": `Dette er IKKE en seriøs datingside!`,
        "step0-warn2": `<b>ADVARSEL!</b> Siden kan inneholde bilder av jenter du kjenner!`,
        "step0-ok": `OK`,
        "step7-thanks": `TAKK!`,
        "step7-message": `Nå kan du se listen over kvinner i ditt område og se på deres frekke bilder. Klikk på knappen "Fortsett" og fortsett til registreringen.`,
        "step7-warn": `Vennligst respekter våre medlemmers konfidensialitet.`,
        "step7-continue": `FORTSETT`
    },
    fi: {
        "generic-continue": `JATKA`,
        "generic-skip": `OHITA`,
        "generic-yes": `KYLLÄ`,
        "step0-warn1": `Tämä EI ole vakava deittisivusto!`,
        "step0-warn2": `<b>VAROITUS!</b> Sivusto voi sisältää kuvia tuntemistasi tytöistä!`,
        "step0-ok": `OK`,
        "step7-thanks": `KIITOS!`,
        "step7-message": `Nyt voit nähdä alueesi naisten listan ja katsoa heidän tuhmia kuviaan. Klikkaa "Jatka" -painiketta ja siirry rekisteröitymiseen.`,
        "step7-warn": `Pidäthän jäsentemme yksityisyyden luottamuksellisena.`,
        "step7-continue": `JATKA`
    },
    da: {
        "generic-continue": `FORTSÆT`,
        "generic-skip": `SPRING OVER`,
        "generic-yes": `JA`,
        "step0-warn1": `Dette er IKKE en seriøs datingside!`,
        "step0-warn2": `<b>ADVARSEL!</b> Siden kan indeholde billeder af piger, du kender!`,
        "step0-ok": `OKAY`,
        "step7-thanks": `TAK!`,
        "step7-message": `Nu kan du se listen over kvinder i dit område og se deres frække billeder. Klik på "Fortsæt" og fortsæt med registreringen.`,
        "step7-warn": `Venligst respekter vores medlemmers fortrolighed.`,
        "step7-continue": `FORTSÆT`
    },
    ja: {
        "generic-continue": `続ける`,
        "generic-skip": `スキップ`,
        "generic-yes": `はい`,
        "step0-warn1": `これは真剣な出会いサイトではありません！`,
        "step0-warn2": `<b>警告！</b> このサイトには知っている女の子の写真が含まれているかもしれません！`,
        "step0-ok": `はい`,
        "step7-thanks": `ありがとう！`,
        "step7-message": `あなたは今、エリア内の女性リストを見ることができ、彼女たちのエッチな写真を閲覧することができます。"続ける"ボタンをクリックして登録に進んでください。`,
        "step7-warn": `当サイトのメンバーの個人情報を守ってください。`,
        "step7-continue": `続ける`
    },
    ko: {
        "generic-continue": `계속하기`,
        "generic-skip": `건너뛰기`,
        "generic-yes": `예`,
        "step0-warn1": `이곳은 진지한 데이트 사이트가 아닙니다!`,
        "step0-warn2": `<b>경고!</b> 이 사이트에는 알고 계신 여성들의 사진이 포함될 수 있습니다!`,
        "step0-ok": `네`,
        "step7-thanks": `감사합니다!`,
        "step7-message": `이제 귀하의 지역의 여성 목록과 그들의 섹시한 사진을 볼 수 있습니다. "계속하기" 버튼을 클릭하고 등록을 진행하세요.`,
        "step7-warn": `회원들의 개인정보를 유지해주십시오.`,
        "step7-continue": `계속하기`
    },
    sk: {
        "generic-continue": `Pokračovať`,
        "generic-skip": `Preskočiť`,
        "generic-yes": `Áno`,
        "step0-warn1": `Toto NIE JE vážny zoznamovací web!`,
        "step0-warn2": `<b>VAROVANIE!</b> Stránka môže obsahovať fotky dievčat, ktoré poznáte!`,
        "step0-ok": `OK`,
        "step7-thanks": `Ďakujeme!`,
        "step7-message": `Teraz môžete vidieť zoznam žien vo vašej oblasti a prezerať si ich pikantné fotografie. Kliknite na tlačidlo "Pokračovať" a pokračujte v registrácii.`,
        "step7-warn": `Prosím, dodržiavajte dôvernosť našich členov.`,
        "step7-continue": `Pokračovať`
    },
    sl: {
        "generic-continue": `NAPREJ`,
        "generic-skip": `PRESKOČI`,
        "generic-yes": `DA`,
        "step0-warn1": `To NI resna zmenkarija!`,
        "step0-warn2": `<b>OPOZORILO!</b> Stran lahko vsebuje fotografije deklet, ki jih poznate!`,
        "step0-ok": `V REDU`,
        "step7-thanks": `HVALA!`,
        "step7-message": `Sedaj lahko vidite seznam žensk v vašem območju in si ogledate njihove poredne fotografije. Kliknite gumb "Naprej" in nadaljujte s registracijo.`,
        "step7-warn": `Prosimo, spoštujte zaupnost naših članov.`,
        "step7-continue": `NAPREJ`
    },
    ua: {
        "generic-continue": `ПРОДОВЖИТИ`,
        "generic-skip": `ПРОПУСТИТИ`,
        "generic-yes": `ТАК`,
        "step0-warn1": `Це НЕ серйозний сайт знайомств!`,
        "step0-warn2": `<b>УВАГА!</b> На сайті можуть бути фотографії дівчат, яких ви знаєте!`,
        "step0-ok": `ГАРЯЧЕ`,
        "step7-thanks": `ДЯКУЮ!`,
        "step7-message": `Тепер ви можете побачити список жінок у вашому регіоні та переглянути їх непослушні фото. Клікніть кнопку "Продовжити" та перейдіть до реєстрації.`,
        "step7-warn": `Будь ласка, зберігайте конфіденційність наших учасників.`,
        "step7-continue": `ПРОДОВЖИТИ`
    },
    ru: {
        "generic-continue": `ПРОДОЛЖИТЬ`,
        "generic-skip": `ПРОПУСТИТЬ`,
        "generic-yes": `ДА`,
        "step0-warn1": `Это НЕ серьезный сайт знакомств!`,
        "step0-warn2": `<b>ВНИМАНИЕ!</b> Сайт может содержать фотографии девушек, которых вы знаете!`,
        "step0-ok": `ХОРОШО`,
        "step7-thanks": `СПАСИБО!`,
        "step7-message": `Теперь вы можете просмотреть список женщин в вашем районе и посмотреть их непослушные фотографии. Нажмите кнопку "Продолжить" и перейдите к регистрации.`,
        "step7-warn": `Пожалуйста, сохраняйте конфиденциальность наших участников.`,
        "step7-continue": `ПРОДОЛЖИТЬ`
    },
    cs: {
        "generic-continue": "POKRAČOVAT",
        "generic-skip": "PŘESKOČIT",
        "generic-yes": "ANO",
        "step0-warn1": "Toto NENÍ seriózní seznamka!",
        "step0-warn2": "<b>UPOZORNĚNÍ!</b> Stránka může obsahovat fotky dívek, které možná znáte!",
        "step0-ok": "V POŘÁDKU",
        "step7-thanks": "DÍKY!",
        "step7-message": "Nyní můžete vidět seznam žen ve vašem okolí a prohlížet si jejich rajcovní fotky. Klikněte na tlačítko \"Pokračovat\" a přejděte k registraci.",
        "step7-warn": "Prosíme, zachovejte důvěrnost našich členek.",
        "step7-continue": "POKRAČOVAT"
    },
    pl: {
        "generic-continue": "DALEJ",
        "generic-skip": "POMIŃ",
        "generic-yes": "TAK",
        "step0-warn1": "To NIE jest poważny portal randkowy!",
        "step0-warn2": "<b>OSTRZEŻENIE!</b> Strona może zawierać zdjęcia dziewczyn, które możesz znać!",
        "step0-ok": "W PORZĄDKU",
        "step7-thanks": "DZIĘKI!",
        "step7-message": "Możesz teraz zobaczyć listę kobiet w swojej okolicy i przeglądać ich pikantne zdjęcia. Kliknij przycisk \"Dalej\" i przejdź do rejestracji.",
        "step7-warn": "Prosimy o zachowanie poufności naszych członkiń.",
        "step7-continue": "DALEJ"
    }
}