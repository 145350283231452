export const QA = {
  step_1: {
    q: [`
      <h2>Most of these girls are desperate unsatisfied wives.</h2>
      <h1>Will you respect their privacy?</h1>
      `, { css: 'privacy' }],
    a: ""
  },
  step_2: {
    q: [`
      <h2>These women are not looking for serious relationships.
      They are only interested in an uncomplicated sex plan.</h2>
      <h1>Do you agree?</h1>
      `, {}],
    a: ""
  },
  step_3: {
    q: ["<h1>Agree to condoms?</h1>", {}],
    a: ""
  },
  step_4: {
    q: ["<h1>What type of body do you find ideal?</h1>", {}],
    a: [
      ["Skinny", { css: 'skiny' }],
      ["Slender", { css: 'slender' }],
      ["Round", { css: 'skiny' }],
      ["Big breasts", { css: 'skiny' }],
      ["Big butt", { css: 'skiny' }]
    ]
  },
  step_5: {
    q: ["<h1>Preferred age?</h1>", {}],
    a: [
      "21-25",
      "26-32",
      "33-41",
      "42-50",
      "51+"
    ]
  },
  step_6: {
    q: ["<h1>What kind of relationships are you looking for?</h1>", {}],
    a: [
      "A sex plan for one night",
      "A sex plan for several times",
      "A regular sex plan without obligations",
      "Serious relationships"
    ]
  }
}

export const QA_translations = {
  es: {
    step_1: {
      q: [`
      <h2>La mayoría de estas chicas son esposas insatisfechas y desesperadas.</h2>
      <h1>¿Respetará su privacidad?</h1>
      `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
      <h2>Estas mujeres no buscan relaciones serias. Solo están interesadas en un plan de sexo sencillo.</h2>
      <h1>¿Está de acuerdo?</h1>
      `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>¿Está de acuerdo en usar condones?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>¿Qué tipo de cuerpo prefiere?</h1>", {}],
      a: [
        ["Delgado", { css: 'skiny' }],
        ["Esbelto", { css: 'slender' }],
        ["Redondeado", { css: 'skiny' }],
        ["Senos grandes", { css: 'skiny' }],
        ["Trasero grande", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>¿Qué edad de mujeres le atrae?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>¿Qué tipo de relaciones busca?</h1>", {}],
      a: [
        "Un plan de sexo para una noche",
        "Un plan de sexo por varias veces",
        "Un plan de sexo regular sin obligaciones",
        "Relaciones serias"
      ]
    }
  },
  fr: {
    step_1: {
      q: [`
          <h2>La plupart de ces filles sont des épouses désespérées et insatisfaites.</h2>
          <h1>Respecterez-vous leur vie privée?</h1>
          `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
          <h2>Ces femmes ne cherchent pas de relations sérieuses.
          Elles sont seulement intéressées par un plan de sexe simple.</h2>
          <h1>Êtes-vous d'accord?</h1>
          `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Êtes-vous d'accord pour utiliser des préservatifs?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Quel type de corps préférez-vous?</h1>", {}],
      a: [
        ["Maigre", { css: 'skiny' }],
        ["Mince", { css: 'slender' }],
        ["Rond", { css: 'skiny' }],
        ["Gros seins", { css: 'skiny' }],
        ["Gros fessier", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Âge préféré?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Quel type de relations recherchez-vous?</h1>", {}],
      a: [
        "Un plan sexe pour une nuit",
        "Un plan sexe pour plusieurs fois",
        "Un plan sexe régulier sans obligation",
        "Des relations sérieuses"
      ]
    }
  },
  fa: {
    step_1: {
      q: [`
          <h2>بیشتر این دختران همسران ناخشنود و ناامیدی هستند. </h2>
          <h1>آیا شما حریم خصوصی آنها را رعایت خواهید کرد؟</h1>
          `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
          <h2>این زنان به دنبال رابطه جدی نیستند. آنها تنها به یک طرح ساده جنسی علاقه دارند.</h2>
          <h1>آیا شما موافقید؟</h1>
          `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>آیا شما با استفاده از کاندوم موافقید؟</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>چه نوع بدنیت را ایده آل می‌دانید؟</h1>", {}],
      a: [
        ["بسیار لاغر", { css: 'skiny' }],
        ["کمی لاغر", { css: 'slender' }],
        ["گرد", { css: 'skiny' }],
        ["سینه بزرگ", { css: 'skiny' }],
        ["پهلوی بزرگ", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>چه سنی از زنان برای شما جذاب است؟</h1>", {}],
      a: [
        "۲۱-۲۵ سال",
        "۲۶-۳۲ سال",
        "۳۳-۴۱ سال",
        "۴۲-۵۰ سال",
        "۵۱ سال به بالا"
      ]
    },
    step_6: {
      q: ["<h1>چه نوع رابطه‌ای به دنبال آن هستید؟</h1>", {}],
      a: [
        "طرح جنسی برای یک شب",
        "طرح جنسی برای چند بار",
        "طرح جنسی منظم بدون التزامات",
        "رابطه جدی"
      ]
    }
  },
  ar: {
    step_1: {
      q: [`
        <h2>معظم هؤلاء الفتيات زوجات يائسات غير راضيات.</h2>
        <h1>هل ستحترم خصوصيتهن؟</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>هؤلاء النساء لا يبحثن عن علاقات جدية.
        إنهن مهتمات فقط بخطة جنسية غير معقدة.</h2>
        <h1>هل توافق؟</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>هل توافق على استخدام الواقي الذكري؟</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>ما هو نوع الجسم الذي تجده مثاليًا؟</h1>", {}],
      a: [
        ["نحيل", { css: 'skiny' }],
        ["متناسق", { css: 'slender' }],
        ["مستدير", { css: 'skiny' }],
        ["ثدي كبير", { css: 'skiny' }],
        ["مؤخرة كبيرة", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>ما هو العمر الذي تفضله في النساء؟</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>ما نوع العلاقات التي تبحث عنها؟</h1>", {}],
      a: [
        "خطة جنسية لليلة واحدة",
        "خطة جنسية لعدة مرات",
        "خطة جنسية منتظمة بدون التزامات",
        "علاقات جدية"
      ]
    }
  },
  it: {
    step_1: {
      q: [`
          <h2>La maggior parte di queste ragazze sono mogli insoddisfatte e disperate.</h2>
          <h1>Rispetterai la loro privacy?</h1>
          `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
          <h2>Queste donne non cercano relazioni serie.
          Sono interessate solo a un piano sessuale non complicato.</h2>
          <h1>Sei d'accordo?</h1>
          `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Accetti di utilizzare i preservativi?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Qual è il tipo di corpo che trovi ideale?</h1>", {}],
      a: [
        ["Magro", { css: 'skiny' }],
        ["Slanciato", { css: 'slender' }],
        ["Tondeggiante", { css: 'skiny' }],
        ["Seni grandi", { css: 'skiny' }],
        ["Sedere grande", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Quali sono le età delle donne a cui sei attratto?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Quali tipi di relazioni stai cercando?</h1>", {}],
      a: [
        "Un piano sessuale per una notte",
        "Un piano sessuale per diverse volte",
        "Un piano sessuale regolare senza obblighi",
        "Relazioni serie"
      ]
    }
  },
  de: {
    step_1: {
      q: [`
          <h2>Die meisten dieser Mädchen sind unzufriedene und verzweifelte Ehefrauen.</h2>
          <h1>Wirst du ihre Privatsphäre respektieren?</h1>
          `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
          <h2>Diese Frauen suchen keine ernsthaften Beziehungen.
          Sie sind nur an einem unkomplizierten Sexplan interessiert.</h2>
          <h1>Bist du einverstanden?</h1>
          `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Stimmst du dem Gebrauch von Kondomen zu?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Was für einen Körpertyp findest du ideal?</h1>", {}],
      a: [
        ["Schlank", { css: 'skiny' }],
        ["Grazil", { css: 'slender' }],
        ["Rund", { css: 'skiny' }],
        ["Große Brüste", { css: 'skiny' }],
        ["Großer Hintern", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Welche Altersgruppe von Frauen spricht dich an?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Wonach suchst du?</h1>", {}],
      a: [
        "Ein Sex-Plan für eine Nacht",
        "Ein Sex-Plan für mehrere Male",
        "Ein regelmäßiger Sex-Plan ohne Verpflichtungen",
        "Ernsthafte Beziehungen"
      ]
    }
  },
  ro: {
    step_1: {
      q: [`
          <h2>Mai multe dintre aceste fete sunt soții nesatisfăcute și disperate.</h2>
          <h1>Vei respecta intimitatea lor?</h1>
          `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
          <h2>Aceste femei nu caută relații serioase.
          Sunt interesate doar de un plan sexual necomplicat.</h2>
          <h1>Ești de acord?</h1>
          `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Ești de acord să folosești prezervative?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Ce tip de corp consideri ideal?</h1>", {}],
      a: [
        ["Slab", { css: 'skiny' }],
        ["Subțire", { css: 'slender' }],
        ["Rotund", { css: 'skiny' }],
        ["Sâni mari", { css: 'skiny' }],
        ["Fund mare", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Ce vârstă a femeilor te atrage?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Ce fel de relații cauți?</h1>", {}],
      a: [
        "Un plan sexual pentru o noapte",
        "Un plan sexual pentru mai multe ori",
        "Un plan sexual regulat, fără obligații",
        "Relații serioase"
      ]
    }
  },
  id: {
    step_1: {
      q: [`
        <h2>Sebagian besar gadis ini adalah istri yang tidak puas dan putus asa.</h2>
        <h1>Apakah Anda akan menghormati privasi mereka?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Para wanita ini tidak mencari hubungan serius.
        Mereka hanya tertarik pada rencana seks tanpa komplikasi.</h2>
        <h1>Apakah Anda setuju?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Setuju menggunakan kondom?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Jenis tubuh seperti apa yang Anda anggap ideal?</h1>", {}],
      a: [
        ["Kurus", { css: 'skiny' }],
        ["Langsing", { css: 'slender' }],
        ["Berisi", { css: 'skiny' }],
        ["Payudara besar", { css: 'skiny' }],
        ["Pantat besar", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Usia wanita yang Anda minati?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Jenis hubungan apa yang Anda cari?</h1>", {}],
      a: [
        "Rencana seks untuk satu malam",
        "Rencana seks untuk beberapa kali",
        "Rencana seks reguler tanpa kewajiban",
        "Hubungan serius"
      ]
    }
  },
  pt: {
    step_1: {
      q: [`
        <h2>A maioria dessas garotas são esposas insatisfeitas e desesperadas.</h2>
        <h1>Você respeitará a privacidade delas?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Essas mulheres não estão procurando relacionamentos sérios.
        Elas estão apenas interessadas em um plano de sexo simples.</h2>
        <h1>Você concorda?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Concorda em usar preservativos?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Que tipo de corpo você considera ideal?</h1>", {}],
      a: [
        ["Magro", { css: 'skiny' }],
        ["Esbelto", { css: 'slender' }],
        ["Cheinho", { css: 'skiny' }],
        ["Seios grandes", { css: 'skiny' }],
        ["Bunda grande", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Idade das mulheres que você se interessa?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Que tipo de relacionamento você está procurando?</h1>", {}],
      a: [
        "Um plano de sexo para uma noite",
        "Um plano de sexo para várias vezes",
        "Um plano de sexo regular sem obrigações",
        "Relacionamentos sérios"
      ]
    }
  },
  sv: {
    step_1: {
      q: [`
        <h2>De flesta av dessa tjejer är missnöjda och desperata fruar.</h2>
        <h1>Kommer du att respektera deras integritet?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Dessa kvinnor söker inte seriösa relationer.
        De är bara intresserade av en okomplicerad sexuell plan.</h2>
        <h1>Stämmer det överens med dig?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Samtycker du till att använda kondomer?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Vilken kroppsform anser du vara idealisk?</h1>", {}],
      a: [
        ["Smal", { css: 'skiny' }],
        ["Slank", { css: 'slender' }],
        ["Rund", { css: 'skiny' }],
        ["Stora bröst", { css: 'skiny' }],
        ["Stor rumpa", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Vilken åldersgrupp av kvinnor attraherar dig?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Vilken typ av relationer söker du?</h1>", {}],
      a: [
        "En sexplan för en natt",
        "En sexplan för flera gånger",
        "En regelbunden sexplan utan förpliktelser",
        "Serious relationer"
      ]
    }
  },
  no: {
    step_1: {
      q: [`
        <h2>De fleste av disse jentene er misfornøyde og desperate koner.</h2>
        <h1>Vil du respektere deres personvern?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Disse kvinnene leter ikke etter seriøse forhold.
        De er bare interessert i en ukomplisert sexplan.</h2>
        <h1>Er du enig?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Er du enig i å bruke kondomer?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Hvilken kroppstype finner du ideell?</h1>", {}],
      a: [
        ["Tynn", { css: 'skiny' }],
        ["Slank", { css: 'slender' }],
        ["Rund", { css: 'skiny' }],
        ["Store bryster", { css: 'skiny' }],
        ["Stor rumpe", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Hvilken aldersgruppe av kvinner tiltrekker deg?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Hvilken type forhold søker du?</h1>", {}],
      a: [
        "En sexplan for én natt",
        "En sexplan for flere ganger",
        "En regelmessig sexplan uten forpliktelser",
        "Serious forhold"
      ]
    }
  },
  fi: {
    step_1: {
      q: [`
        <h2>Suurin osa näistä tytöistä on tyytymättömiä ja epätoivoisia vaimoja.</h2>
        <h1>Kunnioitatko heidän yksityisyyttään?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Nämä naiset eivät etsi vakavia suhteita.
        He ovat kiinnostuneita vain yksinkertaisesta seksisuunnitelmasta.</h2>
        <h1>Oletko samaa mieltä?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Hyväksytkö kondomien käytön?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Minkä tyyppisen vartalon koet ihanteelliseksi?</h1>", {}],
      a: [
        ["Laiha", { css: 'skiny' }],
        ["Hoikka", { css: 'slender' }],
        ["Pyöreä", { css: 'skiny' }],
        ["Iso rinta", { css: 'skiny' }],
        ["Iso takamus", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Minkä ikäisiä naisia sinua kiinnostaa?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Minkä tyyppisiä suhteita etsit?</h1>", {}],
      a: [
        "Yhden illan seksisuunnitelma",
        "Useamman kerran toistuva seksisuunnitelma",
        "Säännöllinen seksisuunnitelma ilman sitoumuksia",
        "Vakavat suhteet"
      ]
    }
  },
  da: {
    step_1: {
      q: [`
        <h2>De fleste af disse piger er utilfredse og desperate koner.</h2>
        <h1>Vil du respektere deres privatliv?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Disse kvinder søger ikke seriøse forhold.
        De er kun interesseret i en ukompliceret sexplan.</h2>
        <h1>Er du enig?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Accepterer du at bruge kondomer?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Hvilken kropstype finder du ideel?</h1>", {}],
      a: [
        ["Tynd", { css: 'skiny' }],
        ["Slank", { css: 'slender' }],
        ["Rund", { css: 'skiny' }],
        ["Store bryster", { css: 'skiny' }],
        ["Stor bagdel", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Hvilken aldersgruppe af kvinder er du tiltrukket af?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Hvilken type relationer søger du?</h1>", {}],
      a: [
        "En sexplan for én nat",
        "En sexplan for flere gange",
        "En regelmæssig sexplan uden forpligtelser",
        "Serious relationer"
      ]
    }
  },
  ja: {
    step_1: {
      q: [`
        <h2>これらのほとんどの女性は不満な妻です。</h2>
        <h1>彼らのプライバシーを尊重しますか？</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>これらの女性は真剣な関係を求めていません。
        彼らは単純なセックスプランに興味があります。</h2>
        <h1>同意しますか？</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>コンドームの使用に同意しますか？</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>どのような体型が理想ですか？</h1>", {}],
      a: [
        ["細身", { css: 'skiny' }],
        ["スレンダー", { css: 'slender' }],
        ["丸みのある", { css: 'skiny' }],
        ["大きな胸", { css: 'skiny' }],
        ["大きなお尻", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>どの年齢の女性が好みですか？</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>どのような関係をお探しですか？</h1>", {}],
      a: [
        "一夜のセックスプラン",
        "数回のセックスプラン",
        "義務のない定期的なセックスプラン",
        "真剣な関係"
      ]
    }
  },
  ko: {
    step_1: {
      q: [`
        <h2>대부분의 여성들은 불만스러운 아내들입니다.</h2>
        <h1>그들의 개인 정보를 존중하시겠습니까?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>이 여성들은 진지한 관계를 찾고 있지 않습니다.
        단순한 섹스 계획에만 관심이 있습니다.</h2>
        <h1>동의하시나요?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>콘돔 사용에 동의하시나요?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>어떤 체형을 이상적으로 생각하십니까?</h1>", {}],
      a: [
        ["날씬한", { css: 'skiny' }],
        ["슬림한", { css: 'slender' }],
        ["통통한", { css: 'skiny' }],
        ["큰 가슴", { css: 'skiny' }],
        ["큰 엉덩이", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>어떤 연령대의 여성에게 관심이 있으십니까?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>어떤 유형의 관계를 찾고 계십니까?</h1>", {}],
      a: [
        "하룻밤 섹스 계획",
        "여러 번의 섹스 계획",
        "의무 없는 정기적인 섹스 계획",
        "진지한 관계"
      ]
    }
  },
  sk: {
    step_1: {
      q: [`
        <h2>Väčšina týchto dievčat je nespokojných a zúfalých manželiek.</h2>
        <h1>Budete rešpektovať ich súkromie?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Tieto ženy nehľadajú vážny vzťah.
        Zaujíma ich iba nekomplikovaný plán sexu.</h2>
        <h1>Súhlasíte?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Súhlasíte s používaním kondómov?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Aký typ postavy považujete za ideálny?</h1>", {}],
      a: [
        ["Chudá", { css: 'skiny' }],
        ["Štíhla", { css: 'slender' }],
        ["Plná", { css: 'skiny' }],
        ["Veľké prsia", { css: 'skiny' }],
        ["Veľký zadok", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Aký vek žien vás zaujíma?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Aký typ vzťahov hľadáte?</h1>", {}],
      a: [
        "Sexuálny plán na jednu noc",
        "Sexuálny plán viackrát",
        "Pravidelný sexuálny plán bez záväzkov",
        "Vážne vzťahy"
      ]
    }
  },
  sl: {
    step_1: {
      q: [`
        <h2>Večina teh deklet je nezadovoljnih in obupanih žena.</h2>
        <h1>Boste spoštovali njihovo zasebnost?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Te ženske ne iščejo resnih razmerij.
        Zanimajo jih le nekomplicirani načrti za seks.</h2>
        <h1>Se strinjate?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Se strinjate z uporabo kondomov?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Kakšno vrsto telesa štejete za idealno?</h1>", {}],
      a: [
        ["Suh", { css: 'skiny' }],
        ["Vitko", { css: 'slender' }],
        ["Okroglo", { css: 'skiny' }],
        ["Velike prsi", { css: 'skiny' }],
        ["Velika zadnjica", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Kakšna starostna skupina žensk vas zanima?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Kakšno vrsto razmerij iščete?</h1>", {}],
      a: [
        "Načrt za eno noč",
        "Načrt za večkrat",
        "Reden načrt brez obveznosti",
        "Resna razmerja"
      ]
    }
  },
  ua: {
    step_1: {
      q: [`
        <h2>Більшість цих дівчат - незадоволені та відчайдушні дружини.</h2>
        <h1>Ви готові поважати їх приватність?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Ці жінки не шукають серйозних стосунків.
        Вони зацікавлені лише в простому плані сексу.</h2>
        <h1>Ви погоджуєтеся?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Ви згодні використовувати презервативи?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Який тип фігури ви вважаєте ідеальним?</h1>", {}],
      a: [
        ["Худа", { css: 'skiny' }],
        ["Струнка", { css: 'slender' }],
        ["Повна", { css: 'skiny' }],
        ["Велика груди", { css: 'skiny' }],
        ["Велика срака", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Яку вікову групу жінок ви цікавитеся?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Який тип стосунків ви шукаєте?</h1>", {}],
      a: [
        "Секс на одну ніч",
        "Секс на кілька разів",
        "Регулярний секс без зобов'язань",
        "Серйозні стосунки"
      ]
    }
  },
  ru: {
    step_1: {
      q: [`
        <h2>Большинство этих девушек - недовольные и отчаянные жены.</h2>
        <h1>Вы готовы уважать их конфиденциальность?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Эти женщины не ищут серьезных отношений.
        Их интересует только непринужденный план секса.</h2>
        <h1>Вы согласны?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Вы согласны использовать презервативы?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Какой тип фигуры вы считаете идеальным?</h1>", {}],
      a: [
        ["Худощавая", { css: 'skiny' }],
        ["Стройная", { css: 'slender' }],
        ["Полненькая", { css: 'skiny' }],
        ["Большая грудь", { css: 'skiny' }],
        ["Большая попа", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Какую возрастную группу женщин вы предпочитаете?</h1>", {}],
      a: [
        "21-25",
        "26-32",
        "33-41",
        "42-50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Какой тип отношений вы ищете?</h1>", {}],
      a: [
        "Секс на одну ночь",
        "Секс на несколько раз",
        "Регулярный секс без обязательств",
        "Серьезные отношения"
      ]
    }
  },
  cs: {
    step_1: {
      q: [`
        <h2>Většina těchto žen jsou zoufalé, neuspokojené manželky.</h2>
        <h1>Budeš respektovat jejich soukromí?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Tyto ženy nehledají vážný vztah.
        Mají zájem pouze o nezávazný sex plán.</h2>
        <h1>Souhlasíš?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Souhlasíš s použitím kondomu?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Jaký typ postavy považuješ za ideální?</h1>", {}],
      a: [
        ["Hubená", { css: 'skiny' }],
        ["Štíhlá", { css: 'slender' }],
        ["Oblá", { css: 'skiny' }],
        ["Velká prsa", { css: 'skiny' }],
        ["Velký zadek", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Preferovaný věk?</h1>", {}],
      a: [
        "21–25",
        "26–32",
        "33–41",
        "42–50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>O jaký typ vztahu máš zájem?</h1>", {}],
      a: [
        "Sex plán na jednu noc",
        "Sex plán na víc nocí",
        "Pravidelný sex plán bez závazků",
        "Vážný vztah"
      ]
    }
  },
  pl: {
    step_1: {
      q: [`
        <h2>Większość z tych kobiet to zdesperowane, niezaspokojone żony.</h2>
        <h1>Czy uszanujesz ich prywatność?</h1>
        `, { css: 'privacy' }],
      a: ""
    },
    step_2: {
      q: [`
        <h2>Te kobiety nie szukają poważnych związków.
        Interesuje je tylko nieskomplikowany plan seksualny.</h2>
        <h1>Zgadzasz się?</h1>
        `, {}],
      a: ""
    },
    step_3: {
      q: ["<h1>Zgadzasz się na używanie prezerwatyw?</h1>", {}],
      a: ""
    },
    step_4: {
      q: ["<h1>Jaki typ sylwetki uważasz za idealny?</h1>", {}],
      a: [
        ["Szczupła", { css: 'skiny' }],
        ["Smukła", { css: 'slender' }],
        ["Krągła", { css: 'skiny' }],
        ["Duże piersi", { css: 'skiny' }],
        ["Duża pupa", { css: 'skiny' }]
      ]
    },
    step_5: {
      q: ["<h1>Preferowany wiek?</h1>", {}],
      a: [
        "21–25",
        "26–32",
        "33–41",
        "42–50",
        "51+"
      ]
    },
    step_6: {
      q: ["<h1>Jakiego rodzaju relacji szukasz?</h1>", {}],
      a: [
        "Plan seksualny na jedną noc",
        "Plan seksualny na kilka spotkań",
        "Regularny seks bez zobowiązań",
        "Poważny związek"
      ]
    }
  }
}